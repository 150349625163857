import type { BookingRequestImmutableData } from '~/types/bookingRequest'
import type { AllNullable } from '~/types/commons'

export function assertBookingRequestMandatoryData(parameters: unknown): asserts parameters is BookingRequestImmutableData {
  if (typeof parameters !== 'object' || parameters === null) {
    throw new Error('parameters must be a non-null object')
  }
  const maybeNullParameters = parameters as AllNullable<BookingRequestImmutableData>
  assertAllNullableTypeIsType<BookingRequestImmutableData>(maybeNullParameters, 'camper_id', 'date_from', 'date_to', 'hour_from', 'hour_to', 'km_option', 'insurance_id')
  const validParameters = maybeNullParameters as BookingRequestImmutableData
  assertHourFrom(validParameters.hour_from)
  assertHourTo(validParameters.hour_to)
  assertKilometersOption(validParameters.km_option)
}
