<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { Review, ReviewsQueryParameters, VehicleTypeInteger } from '@yescapa-dev/ysc-api-js/legacy'
import type { EnumToPrimitiveUnion } from '@yescapa-dev/ysc-api-js/modern'
import { YSC_API_REVIEW_ERROR } from '~/utils/error/YscErrorClasses'

interface Props {
  camperId: number
  ownerId: number
  ownerFirstName?: string
  vehicleType?: VehicleTypeInteger
  ownerReviewAverage: number
  ownerReviewCount: number
}

const props = defineProps<Props>()

const { t } = useI18n()

const reviewsVehicleCount = ref(0)

type ReviewsAggregate = {
  results: Review[]
  next: string | null
  count: number
  page: number
}

const reviews = ref<ReviewsAggregate>({
  results: [],
  count: 0,
  next: null,
  page: 1,
})

enum reviewType {
  CAMPER = 'camper',
  OWNER = 'owner',
}

const route = useRoute()
const activeTab = computed<EnumToPrimitiveUnion<reviewType>>(() => route.query.review && route.query.review === reviewType.OWNER ? reviewType.OWNER : reviewType.CAMPER)
const tabs = computed(() => [
  {
    to: useGetRouteWith({ query: { ...route.query, review: reviewType.CAMPER } }),
    label: t('components.app_camper_reviews.vehicle'),
    customData: {
      count: reviewsVehicleCount.value,
    },
    key: reviewType.CAMPER,
  },
  {
    to: useGetRouteWith({ query: { ...route.query, review: reviewType.OWNER } }),
    label: t('components.app_camper_reviews.owner'),
    customData: {
      count: props.ownerReviewCount - reviewsVehicleCount.value,
    },
    key: reviewType.OWNER,
  },
])

const PAGE_SIZE = 4

const page = ref(queryValueToNumber(route.query.page) || 1)

const resetPage = async () => {
  await navigateTo({
    query: {
      ...route.query, page: 1,
    },
  })
}

watch(() => route.query.page, (value) => {
  page.value = queryValueToNumber(value) ?? 1
}, { immediate: true })

watch(() => route.query.review, async () => {
  if (route.query.review && Object.values(reviewType).includes(route.query.review.toString() as reviewType)) {
    const previousPage = queryValueToNumber(route.query.page) ?? 1
    await resetPage()
    if (previousPage === 1) {
      await refresh()
    }
  }
})

const { status, refresh } = await useAsyncData('camper-reviews', async () => {
  const { $api } = useYscApi()
  const { $errorManager } = useErrorManager()
  page.value = queryValueToNumber(route.query.page) || 1

  let results: Review[] = [], next: string | undefined, count: number
  try {
    const params: ReviewsQueryParameters = {
      page_size: PAGE_SIZE,
      page: page.value,
      author_type: 'guest',
    }
    if (activeTab.value === reviewType.CAMPER) {
      params.product_id = props.camperId
    }
    else {
      params.product_id__not_in = props.camperId.toString()
      params.target_id = props.ownerId
    }
    const data = await $api.reviews.getAll(params)
    results = data.results
    next = data.next
    count = data.count
    if (activeTab.value === reviewType.CAMPER) {
      reviewsVehicleCount.value = data.count
    }
  }
  catch (e) {
    if (e instanceof Error) {
      $errorManager({ e, name: YSC_API_REVIEW_ERROR })
      await resetPage()
    }
    return
  }
  reviews.value.page = page.value
  reviews.value.results = results
  reviews.value.next = next ?? null
  reviews.value.count = count
}, {
  watch: [page],
})

const title = computed(() => {
  const value_string = props.ownerFirstName
  switch (props.vehicleType) {
    case Constants.PRODUCTS.TYPES.LOWPROFILE:
      return t('pages.campers.best_reviews.lowprofile_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.COACHBUILT:
      return t('pages.campers.best_reviews.coachbuilt_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.ACLASS:
      return t('pages.campers.best_reviews.aclass_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.CAMPERVAN:
      return t('pages.campers.best_reviews.campervan_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.VAN:
      return t('pages.campers.best_reviews.van_dynamic', { value_string })
    case Constants.PRODUCTS.TYPES.CARAVAN:
      return t('pages.campers.best_reviews.caravan_dynamic', { value_string })
    default:
      return t('pages.campers.best_reviews.default_dynamic', { value_string })
  }
})

const modalePhotoPath = ref<string | null>(null)

const onPhotoClick = (path: string) => {
  modalePhotoPath.value = path
}

const hasBestReviews = computed(() => props.ownerReviewAverage >= 4.5 && props.ownerReviewCount >= 10)
</script>

<template>
  <div class="grid md:grid-cols-7 gap-5">
    <div class="flex flex-col md:col-span-2 items-center space-y-1.5">
      <div class="flex space-x-2">
        <img
          v-if="hasBestReviews"
          src="~/assets/img/reviews/laurel.svg"
        >
        <i18n-t
          v-if="ownerReviewAverage > 0"
          keypath="pages.campers.review_dynamic"
          tag="span"
          scope="global"
          class="flex flex-col items-center"
        >
          <template #emphasis_string>
            <span class="block text-5xl">{{ $n(ownerReviewAverage, {
              minimumFractionDigits: 1,
            }) }}
            </span>
          </template>
        </i18n-t>
        <img
          v-if="hasBestReviews"
          src="~/assets/img/reviews/laurel.svg"
          class=" -scale-x-100"
        >
      </div>
      <YscRating
        :model-value="ownerReviewAverage"
        small
        class="text-pink-500"
      />
    </div>
    <div
      class="md:col-span-5"
    >
      <div
        v-if="ownerReviewCount"
        class="space-y-10"
      >
        <p
          v-if="hasBestReviews"
          class="md:text-lg py-3 md:py-5"
        >
          <span class="font-semibold">{{ title }}</span>
          <br>{{ $t('pages.campers.best_reviews.one_of_the_best') }}
        </p>
        <YscMenuTabs
          v-if="tabs[1].customData.count"
          :default-active="activeTab"
          :tabs="tabs"
          class="border-b border-gray-200"
        />
        <YscDivider v-else />
        <AppLoading v-if="status === 'pending'" />
        <template v-else>
          <div
            v-for="review in reviews.results"
            :key="review.id"
            class="break-inside-avoid-column"
          >
            <AppReview
              :id-review="review.id"
              :note="review.avg"
              :photos="review.photos"

              @photo-click="onPhotoClick"
            >
              <template #avatar>
                <YscAvatar
                  :first-name="review.author.first_name"
                  :picture="review.author.photo"
                  radius="28"
                />
              </template>

              <template #user-name>
                {{ review.author.first_name }}
              </template>

              <template
                v-if="review.published_date"
                #user-description
              >
                {{ $d(new Date(review.published_date)) }}
              </template>

              <template #description>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="review.text" />
              </template>
            </AppReview>
          </div>
        </template>
        <YscModal
          v-if="modalePhotoPath"
          @close="modalePhotoPath = null"
        >
          <TwicImg
            :src="twicpicsPath(modalePhotoPath)"
            ratio="4/3"
            class="shrink-0 rounded"
            placeholder="maincolor"
          />
        </YscModal>
        <template v-if="!reviews.count">
          <p>{{ $t('commons.no_reviews') }}</p>
          <a
            class="link link-primary cursor-pointer"
            @click="navigateTo({ query: { review: reviewType.OWNER } })"
          >
            {{ $t('components.app_camper_reviews.see_others_dynamic', { owner_string: ownerFirstName }) }}</a>
        </template>
        <YscPagination
          :page-size="PAGE_SIZE"
          :count="reviews.count"
        />
      </div>

      <p v-else>
        {{ $t('pages.campers.no_review_yet') }}
      </p>
    </div>
  </div>
</template>
