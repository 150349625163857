<script setup lang="ts">
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { Camper, Currencies, VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'

const { t, n } = useI18n()
const { getReferentialMaps } = useReferentialStore()

interface Props {
  camper: Camper
  vehicle: VehicleResponse
}

const props = defineProps<Props>()

const { localeProperties: { yscCode } } = useLocaleProperties()

const { ALLOWED, NOT_ALLOWED, ON_DEMAND } = Constants.PRODUCTS.OWNER_ACCEPTANCE_STATUS

const allowedOptions = computed(() => {
  return {
    [ALLOWED]: upperCaseFirstLetter(t('commons.options.allowed_plural', 1), yscCode),
    [NOT_ALLOWED]: upperCaseFirstLetter(t('commons.options.not_allowed_plural', 1), yscCode),
    [ON_DEMAND]: upperCaseFirstLetter(t('commons.options.on_demand'), yscCode),
  }
})

const conditions = computed(() => {
  const currency = getReferentialMaps('currency')[props.camper.currency_used as Currencies]
  const { NOT_ALLOWED } = Constants.PRODUCTS.OWNER_ACCEPTANCE_STATUS
  return [
    {
      key: 'abroad_allowed',
      label: t('data.product.abroad_allowed.label'),
      value: props.camper ? allowedOptions.value[props.camper.abroad_allowed] : NOT_ALLOWED,
      icon: resolveComponent('YscIconsWorld'),
    },
    {
      key: 'driving_license',
      label: t('commons.documents.driving_licen.title'),
      value: props.vehicle.driving_licence_required === Constants.VEHICLES.DRIVING_LICENCE.C ? t('components.app_search.filters.license_c') : t('components.app_search.filters.license_b'),
      icon: resolveComponent('YscIconsDrivingLicense'),
    },
    {
      key: 'pets_allowed',
      label: t('data.product.pets_allowed.label'),
      value: props.camper ? allowedOptions.value[props.camper.pets_allowed] : NOT_ALLOWED,
      icon: props.camper.pets_allowed === NOT_ALLOWED ? resolveComponent('YscIconsPetsNotAllowed') : resolveComponent('YscIconsPetsAllowed'),
    },
    {
      key: 'smoking_allowed',
      label: t('data.product.smoking_allowed.label'),
      value: props.camper ? allowedOptions.value[props.camper.smoking_allowed] : NOT_ALLOWED,
      icon: props.camper.smoking_allowed === NOT_ALLOWED ? resolveComponent('YscIconsSmokingNotAllowed') : resolveComponent('YscIconsSmokingAllowed'),
    },
    {
      key: 'km',
      label: t('data.conditions.km_more.label'),
      value: props.camper.price_km_more
        ? t('pages.campers.owner_conditions.price_km_more_dynamic', {
            value_string: n(props.camper.price_km_more, currency),
          })
        : t('commons.free'),
      icon: resolveComponent('YscIconsMileage'),
    },
  ]
})
</script>

<template>
  <ul class="grid sm:grid-cols-2 gap-x-8 gap-y-4 md:gap-y-6">
    <li
      v-for="item in conditions"
      :key="item.key"
      class="flex items-start gap-x-3"
    >
      <component
        :is="item.icon"
        class="h-6 w-6"
      />
      <div class="flex flex-col">
        <span class="font-semibold">{{ item.label }}</span>
        <span>{{ item.value }}</span>
      </div>
    </li>
  </ul>
</template>
