<script setup lang="ts">
import type { VehicleResponse } from '@yescapa-dev/ysc-api-js/legacy'
import { YSC_API_SEARCH_ERROR } from '~/utils/error/YscErrorClasses'
import { getSimilarQuery } from '~/utils/vehicle'

interface Props {
  camperId: number
  vehicle: VehicleResponse
  dateFrom?: string
  dateTo?: string
}

const props = defineProps<Props>()
const { $errorManager } = useErrorManager()

const SIMILAR_CAMPER_SIZE = 3

const { searchCampers } = useSearchCampers()

const { data, pending, error } = useAsyncData(`similar-campers-${props.camperId}`, async () => {
  const query = getSimilarQuery({
    id: props.camperId,
    vehicle: props.vehicle,
    date_from: props.dateFrom,
    date_to: props.dateTo,
    page_size: SIMILAR_CAMPER_SIZE,
  })

  const { results } = await searchCampers(query)
  return { results }
})

if (error.value) {
  $errorManager({ e: error.value, name: YSC_API_SEARCH_ERROR })
  throw createError(error.value)
}
</script>

<template>
  <div>
    <AppLoading v-if="pending" />
    <AppContentScroller
      v-else-if="data?.results.length === SIMILAR_CAMPER_SIZE"
      class="mt-2 gap-x-8 sm:mt-4 xl:justify-center"
      hide-scroll-indicator
    >
      <AppSearchResultsCamperCard
        v-for="camper of data.results"
        :key="camper.id"
        :camper="camper"
        :show-wishlist-button="false"
        :show-discount="false"
        class="min-w-72"
      />
    </AppContentScroller>
    <p
      v-else
      class="pt-12"
    >
      {{ $t('pages.campers.no_similar') }}
    </p>
  </div>
</template>
